import { Status, StatusType } from '@paytently/types';

import Block from '@material-symbols/svg-400/rounded/block.svg?react';
import CheckCircle from '@material-symbols/svg-400/rounded/check_circle.svg?react';
import CreditCardOff from '@material-symbols/svg-400/rounded/credit_card_off.svg?react';
import Error from '@material-symbols/svg-400/rounded/error.svg?react';
import Schedule from '@material-symbols/svg-400/rounded/schedule.svg?react';
import TimerPause from '@material-symbols/svg-400/rounded/timer_pause.svg?react';

const getSizeProps = ({ size }: { size: number }) => ({
  height: size,
  width: size,
});

const getStatusIcons = ({ size }: { size: number }) => {
  const sizeProps = getSizeProps({ size });
  return {
    [Status.captured]: <CheckCircle {...sizeProps} fill="var(--mantine-color-green-0)" />,
    [Status.pending]: <Schedule {...sizeProps} fill="var(--mantine-color-yellow-0)" />,
    [Status.abandoned]: <TimerPause {...sizeProps} fill="var(--mantine-color-blue-1)" />,
    [Status.declined]: <Block {...sizeProps} fill="var(--mantine-color-red-0)" />,
    [Status.voided]: <CreditCardOff {...sizeProps} fill="var(--mantine-color-bordeaux-0)" />,
    [Status.failed]: <Error {...sizeProps} fill="var(--mantine-color-red-0)" />,
  };
};

interface Props {
  status: StatusType;
  size?: number;
}
export function TransactionStatusIcon({ status, size = 16 }: Props) {
  return getStatusIcons({ size })[status];
}
