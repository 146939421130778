import { ReactNode } from 'react';

import { DrawerProps, Drawer, ScrollArea, ActionIcon } from '@mantine/core';

import CloseIcon from '@material-symbols/svg-400/rounded/close.svg?react';

import { BottomAppBar, TopAppBar } from '../AppBar';
import { Title } from '../Typography';
import { useDisplayMode } from '../theme/displayModeHelpers';

import styles from './SideSheet.module.css';

export function SideSheetExtend() {
  return Drawer.extend({
    defaultProps: {
      classNames: { body: styles.drawerBody },
      scrollAreaComponent: ScrollArea.Autosize,
      position: 'right',
    },
  });
}

export function SideSheet({
  title,
  children,
  primaryButton,
  secondaryButton,
  onClose,
  onBack,
  ...rest
}: {
  title: string;
  children: ReactNode;
  onClose?: () => void;
  onBack?: () => void;
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
} & DrawerProps) {
  const { isInMobileMode } = useDisplayMode();

  const header = isInMobileMode ? (
    <TopAppBar title={title} onBack={onBack} />
  ) : (
    <ActionIcon onClick={onClose}>
      <CloseIcon fill="var(--mantine-color-neutral-3)" />
    </ActionIcon>
  );

  const headerHeight = isInMobileMode ? 56 : 80;
  const titlePadding = 'lg';

  return (
    <Drawer.Root
      onClose={onClose}
      w={isInMobileMode ? '100%' : ''}
      styles={{ inner: { display: isInMobileMode ? 'block' : 'flex' } }}
      {...rest}
    >
      <Drawer.Overlay />
      <Drawer.Content>
        <Drawer.Header
          h={headerHeight}
          mih={headerHeight}
          px={0}
          mb={isInMobileMode ? titlePadding : 0}
        >
          {header}
        </Drawer.Header>
        <Drawer.Body>
          {!isInMobileMode && (
            <Title order={1} weight="medium" mb={titlePadding}>
              {title}
            </Title>
          )}
          {children}
        </Drawer.Body>
        <div className={styles.footer}>
          <BottomAppBar
            primaryButtons={primaryButton}
            secondaryButtons={secondaryButton}
            scrolled={true}
          />
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
}
