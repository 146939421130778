import { Outlet } from 'react-router-dom';

import { PageProps } from '@paytently/types';

import { useDocumentTitle } from '@#/hooks';
import { Payments } from '@#/pages';

export function PaymentsPageLayout({ title }: PageProps) {
  useDocumentTitle(title);
  return (
    <>
      <Payments />
      <Outlet />
    </>
  );
}
