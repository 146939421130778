export enum FilterTypes {
  range = 'range',
  multiSelect = 'multiSelect',
  singleSelect = 'singleSelect',
  chips = 'chips',
  boolean = 'boolean',
  input = 'input',
  schemeSelection = 'schemeSelection',
}

type FilterType = keyof typeof FilterTypes;

export type MultiSelectDataType = { value: string; label: string }[];

// Each type of filter and the props they take as well as the value they output after being applied
type FilterConfig =
  | {
      type: FilterTypes.range;
      props: { min: number; max: number };
      value: { min?: number; max?: number };
    }
  | {
      type: FilterTypes.multiSelect;
      props: { data: MultiSelectDataType };
      value: MultiSelectDataType;
    }
  | {
      type: FilterTypes.singleSelect;
      props: { data: string[] };
      value: string;
    }
  | {
      type: FilterTypes.chips;
      props: { data: string[] };
      value: string;
    }
  | {
      type: FilterTypes.boolean;
      props: { data: boolean };
      value: boolean;
    }
  | {
      type: FilterTypes.input;
      props: { data: string };
      value: string;
    }
  | {
      type: FilterTypes.schemeSelection;
      props: { data: MultiSelectDataType };
      value: MultiSelectDataType;
    };

// A version of FilterConfigs that is indexable by FilterType
export type FilterConfigMap = {
  [K in FilterConfig['type']]: Extract<FilterConfig, { type: K }>;
};

export type GenerateSearchFilterType<T = any, U = any> = (
  data: T,
  previousFilters?: AppliedFilters | undefined
) => U;

type BaseFilterOption<T = any, U = any> = {
  label: string;
  description?: string;
  internalOnly?: boolean;
  generateSearchFilter: GenerateSearchFilterType<T, U>;
};

// Wraps each filter type with BaseFilterOptions to make it a selectable input
export type FilterOptionType = {
  [K in keyof FilterConfigMap]: BaseFilterOption & {
    type: K;
    props: FilterConfigMap[K]['props'];
  };
}[keyof FilterConfigMap];

/** e.g:
 * {
 *    amount: {
 *      type: range,
 *      value: {
 *        min: 0,
 *        max: 100
 *      }
 *    }
 */
export type AppliedFilters = {
  [Label in string]: {
    [T in FilterTypes]: {
      type: T;
      value: FilterConfigMap[T]['value'];
      searchFilter: any;
    };
  }[FilterType];
};
