import { AccessLevel, AccessLevelType } from '@paytently/types';

export type SupportedComponentTypes = 'date' | 'scheme' | 'payment-status' | 'payment-reject';

export type SectionDataPoint = {
  value: string[];
  label: string;
  type?: SupportedComponentTypes;
  permissions?: AccessLevelType[];
  enabled: boolean;
};

export type Section = {
  id: string;
  label: string;
  requires?: string[];
  permissions?: AccessLevelType[];
  data: SectionDataPoint[];
  order: 1 | 2;
};

export const TransactionDetailDataPoints: Section[] = [
  {
    id: 'void-info',
    label: 'Void details',
    requires: ['voidInfo'],
    order: 1,
    data: [
      { value: ['voidInfo', 'date'], label: 'Date & time', type: 'date', enabled: true },
      { value: ['voidInfo', 'reason'], label: 'Void reason', enabled: true },
      { value: ['voidInfo', 'voidedBy'], label: 'Voided by', enabled: true },
    ],
  },
  {
    id: 'payment-details',
    label: 'Payment details',
    order: 1,
    data: [
      {
        value: ['requested_on'],
        label: 'Date & Time',
        type: 'date',
        enabled: true,
      },
      {
        value: ['id'],
        label: 'Payment ID',
        enabled: true,
      },
      {
        value: ['reference'],
        label: 'Reference',
        enabled: true,
      },
      {
        value: ['status'],
        label: 'Status',
        type: 'payment-status',
        enabled: true,
      },
      {
        value: ['reason', 'message'],
        label: 'reason',
        type: 'payment-reject',
        enabled: true,
      },
      {
        value: ['reason', 'code'],
        label: 'code',
        type: 'payment-reject',
        enabled: true,
      },
      {
        value: [''],
        label: 'Channel',
        enabled: true,
      },
      {
        value: [''],
        label: 'Connector',
        permissions: [AccessLevel.internal],
        enabled: true,
      },
      {
        value: [''],
        label: 'Acquirer',
        permissions: [AccessLevel.internal],
        enabled: true,
      },
      {
        value: [''],
        label: 'MID',
        permissions: [AccessLevel.internal],
        enabled: true,
      },
      {
        value: ['route_id'],
        label: 'Route ID',
        enabled: true,
      },
      {
        value: ['connector_transaction_id'],
        label: 'External ID',
        permissions: [AccessLevel.internal],
        enabled: true,
      },
      {
        value: ['metadata'],
        label: 'Metadata',
        enabled: false,
      },
      {
        value: ['currency'],
        label: 'Currency',
        enabled: true,
      },
      {
        value: ['amount'],
        label: 'Transaction amount',
        enabled: true,
      },
      {
        value: ['amount_details', 'total_captured'],
        label: 'Captured amount',
        enabled: true,
      },
      {
        value: ['3ds'],
        label: '3DS check',
        enabled: false, // Enable once 3DS data is returned from the API
      },
      {
        value: [''],
        label: 'Authorisation code',
        enabled: true,
      },
      {
        value: [''],
        label: 'Descriptor',
        enabled: true,
      },
      {
        value: [''],
        label: 'Entity',
        permissions: [AccessLevel.internal],
        enabled: true,
      },
    ],
  },
  {
    id: 'beneficiary-details',
    label: 'Beneficiary details',
    order: 1,
    data: [
      {
        value: [''],
        label: 'Entity',
        enabled: true,
      },
      {
        value: [''],
        label: 'Entity ID',
        permissions: [AccessLevel.internal],
        enabled: true,
      },
      {
        value: [''],
        label: 'Application ID',
        enabled: true,
      },
    ],
  },
  {
    id: 'customer-details',
    label: 'Customer details',
    order: 2,
    data: [
      {
        value: ['customer', 'id'],
        label: 'Customer ID',
        enabled: true,
      },
      {
        value: ['customer', 'first_name'],
        label: 'Customer name',
        enabled: true,
      },
      {
        value: ['customer', 'birth_date'],
        label: 'Birth date',
        type: 'date',
        enabled: true,
      },
      {
        value: ['customer', 'contact', 'email'],
        label: 'Email address',
        enabled: true,
      },
      {
        value: ['customer', 'contact', 'phone', 'number'],
        label: 'Phone number',
        enabled: true,
      },
      {
        value: ['billing_address', 'line_one'],
        label: 'Billing address',
        enabled: true,
      },
      {
        value: ['billing_address', 'postal_code'],
        label: 'Postcode',
        enabled: true,
      },
      {
        value: ['billing_address', 'country'],
        label: 'Country',
        enabled: true,
      },
      {
        value: ['billing_address', 'ip_address'],
        label: 'IP address',
        enabled: true,
      },
    ],
  },
  {
    id: 'card-details',
    label: 'Card details',
    order: 2,
    data: [
      {
        value: ['method', 'card', 'name'],
        label: 'Name on card',
        enabled: true,
      },
      {
        value: ['method', 'card', 'number'],
        label: 'Card number',
        enabled: true,
      },
      {
        value: ['method', 'scheme'],
        label: 'Scheme',
        type: 'scheme',
        enabled: true,
      },
      {
        value: ['method', 'card', 'issuer', 'name'],
        label: 'Issuing bank',
        enabled: true,
      },
      {
        value: ['method', 'card', 'issuer', 'country'],
        label: 'Issuing country',
        enabled: true,
      },
      {
        value: [''],
        label: 'Card type',
        enabled: true,
      },
      {
        value: [''],
        label: 'Card segment',
        enabled: true,
      },
    ],
  },
];
