import { NavigationLink } from '@paytently/types';

import CreditCard from '@material-symbols/svg-400/rounded/credit_card.svg?react';
import Dashboard from '@material-symbols/svg-400/rounded/dashboard.svg?react';
import ManageAccounts from '@material-symbols/svg-400/rounded/manage_accounts.svg?react';

export const NavigationLinks: NavigationLink[] = [
  { path: '/', label: 'Home', icon: <Dashboard /> },
  { path: '/payments', label: 'Payments', icon: <CreditCard /> },
  { path: '/user_management', label: 'User Management', icon: <ManageAccounts /> },
];
