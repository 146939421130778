import { ReactNode } from 'react';

import { Button, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { ModalActions } from '@paytently/ui';

type ConfirmModalProps = {
  children: ReactNode;
  onCancel?: () => void;
  onConfirm: () => void;
  labels?: { confirm?: string; cancel?: string };
};

export const ConfirmModal = ({
  context,
  id,
  innerProps: { children, onCancel, onConfirm, labels },
}: ContextModalProps<ConfirmModalProps>) => (
  <Stack gap="md">
    {children}
    <ModalActions>
      <Button
        color="neutral.3"
        variant="subtle"
        size="md"
        onClick={() => (onCancel ? onCancel() : context.closeModal(id))}
      >
        {labels?.cancel || 'Cancel'}
      </Button>
      <Button color="secondary.1" variant="subtle" size="md" onClick={onConfirm}>
        {labels?.confirm || 'Confirm'}
      </Button>
    </ModalActions>
  </Stack>
);
