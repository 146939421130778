import { PageProps } from '@paytently/types';

import TransactionDetails from '@#/components/TransactionDetails/TransactionDetails';

import { useDocumentTitle } from '@#/hooks';

export function PaymentDetails({ title }: PageProps) {
  useDocumentTitle(title);
  return <TransactionDetails />;
}
