import { MRT_Cell, MRT_Row } from 'mantine-react-table';
import { ReactNode } from 'react';

import { Skeleton } from '@mantine/core';

import { PaymentsSearchResponseItem, SchemeType, Status, StatusType } from '@paytently/types';
import { useDisplayMode } from '@paytently/ui';

import { CardSchemeIcon } from '@#/components/CardSchemeIcon/CardSchemeIcon';
import { TablePaymentStatus } from '@#/components/PaymentStatus/PaymentStatus';

import { useFormattedDate } from '@#/hooks';
import { PaymentsDataTableItem } from '@#/pages/Payments/PaymentsTable/PaymentsTable';
import { getConnectorChannelByChannelId } from '@#/utils';

function RenderChildrenOrPlaceHolder({
  cell,
  children,
}: {
  cell: MRT_Cell<PaymentsDataTableItem>;
  children: ReactNode;
}) {
  return cell.getValue() === undefined ? <Skeleton width="100%">-</Skeleton> : <>{children}</>;
}

export function GenericCell({ cell }: { cell: MRT_Cell<PaymentsDataTableItem> }) {
  return (
    <RenderChildrenOrPlaceHolder cell={cell}>{cell.getValue<string>()}</RenderChildrenOrPlaceHolder>
  );
}

export function StatusCell({ cell }: { cell: MRT_Cell<PaymentsDataTableItem> }) {
  const { isInMobileMode } = useDisplayMode();

  return (
    <RenderChildrenOrPlaceHolder cell={cell}>
      <TablePaymentStatus status={cell.getValue<StatusType>()} iconOnly={isInMobileMode} />
    </RenderChildrenOrPlaceHolder>
  );
}

export function DateCell({ cell }: { cell: MRT_Cell<PaymentsDataTableItem> }) {
  return (
    <RenderChildrenOrPlaceHolder cell={cell}>
      {useFormattedDate({ date: cell.getValue<string>() })}
    </RenderChildrenOrPlaceHolder>
  );
}

export function AmountCell({
  cell,
  row,
}: {
  cell: MRT_Cell<PaymentsDataTableItem>;
  row: MRT_Row<PaymentsDataTableItem>;
}) {
  // Get the status of this row
  const status = row
    .getAllCells()
    .find((rowCell) => rowCell.column.id === 'status')
    ?.getValue() as StatusType;

  // If one of the "failed" types, strikethrough the amount
  const failedTypes: StatusType[] = [
    Status.abandoned,
    Status.declined,
    Status.failed,
    Status.voided,
  ];
  const strikethrough = failedTypes.includes(status);

  const amount = cell.getValue<string>();
  const text = strikethrough ? <s>{amount}</s> : <span>{amount}</span>;

  return <RenderChildrenOrPlaceHolder cell={cell}>{text}</RenderChildrenOrPlaceHolder>;
}

export function ChannelCell({ cell }: { cell: MRT_Cell<PaymentsDataTableItem> }) {
  const route = cell.getValue<PaymentsSearchResponseItem['route']>();
  const channel = getConnectorChannelByChannelId({
    channelId: route?.channel.id,
  });

  return <RenderChildrenOrPlaceHolder cell={cell}>{channel?.name}</RenderChildrenOrPlaceHolder>;
}

export function SchemeCell({ cell }: { cell: MRT_Cell<PaymentsDataTableItem> }) {
  const cellValue = cell.getValue<SchemeType>();

  return (
    <RenderChildrenOrPlaceHolder cell={cell}>
      <span>
        <CardSchemeIcon scheme={cellValue} />
      </span>
    </RenderChildrenOrPlaceHolder>
  );
}
