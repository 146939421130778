import { path } from 'ramda';
import { useState } from 'react';

import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { SnackbarBody, Text } from '@paytently/ui';

type CSVdataMapper = {
  header: string;
  dataPoint: string[];
};

// TODO: Refactor out of custom hook
const csvDataMapper: CSVdataMapper[] = [
  {
    header: 'Transaction ID',
    dataPoint: ['id'],
  },
  {
    header: 'Status',
    dataPoint: ['status'],
  },
  {
    header: 'Date',
    dataPoint: ['requested_on'],
  },
  {
    header: 'Amount',
    dataPoint: ['amount'],
  },
  {
    header: 'Payment Channel',
    dataPoint: ['payment_channel'],
  },
  {
    header: 'Cardholder',
    dataPoint: ['method', 'card', 'name_on_card'],
  },
  {
    header: 'Scheme',
    dataPoint: ['method', 'scheme'],
  },
];

export const useDownloadJsonAsCsv = (data: any, fileName: string) => {
  const convertJSONToCSV = (data) => {
    try {
      const headers = csvDataMapper.map(({ header }) => header);

      const csvRows = data.map((row) =>
        csvDataMapper.map(({ dataPoint }) => path(dataPoint, row)).join(',')
      );

      return [headers.join(','), ...csvRows].join('\n');
    } catch (error) {
      throw new Error('Failed to convert data to CSV format.');
    }
  };

  const downloadCsv = () => {
    try {
      if (!data || data.length < 1) {
        throw new Error('No data available to download');
      }

      const csvData = convertJSONToCSV(data);

      notifications.show({
        message: <SnackbarBody message="Processing..." />,
        position: 'bottom-center',
      });

      // Create a Blob from the CSV
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      // Create a temporary link to download the file
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;

      // Programmatically trigger the link click to initiate a file download
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the Object URL and removing the link
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }, 100);
    } catch (error: any) {
      const errorMessage = error?.message ?? 'Failed to download report';

      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: 'Export error',
          text: 'There was an unexpected error in exporting report. We apologise for the inconvenience. If the error appears again please contact your customer support manager.',
          errorColor: 'var(--mantine-color-yellow-0)',
          getSupportAction: () => alert('get support clicked'),
        },
      });
    }
  };

  return { downloadCsv };
};
