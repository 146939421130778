import { useMutation } from "@tanstack/react-query";
import { suspendUser } from "../../api/authentication";
import { SuspendUserRequest } from "@paytently/types";

export function useSuspendUser() {
  const query = useMutation({
    mutationFn: ({ entityId, userId }: SuspendUserRequest) =>
      suspendUser({ entityId, userId }),
  });
  return query;
}
