import { Link, useNavigate } from 'react-router-dom';

import { ActionIcon, Button, Flex } from '@mantine/core';

import CloseIcon from '@material-symbols/svg-400/rounded/close.svg?react';

import styles from './TransactionQuickViewHeader.module.css';

interface Props {
  readonly transactionId: string;
}

export function TransactionQuickViewHeader({ transactionId }: Props) {
  const navigate = useNavigate();
  return (
    <Flex
      justify="space-between"
      align="center"
      pt="lg"
      pb="md"
      px="xl"
      className={styles.container}
    >
      <Button
        classNames={{ inner: styles.button }}
        p={0}
        size="md"
        color="var(--mantine-color-neutral-2)"
        variant="transparent"
        component={Link}
        to={`/details/${transactionId}`}
      >
        Open full page
      </Button>

      <ActionIcon size="sm" aria-label="Close quick page" onClick={() => navigate('/payments')}>
        <CloseIcon fill="var(--mantine-color-neutral-3)" />
      </ActionIcon>
    </Flex>
  );
}
