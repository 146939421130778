import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { Outlet } from 'react-router-dom';

export function PrivateRoute() {
  const { isAuthenticated, isLoading, login } = useKindeAuth();

  if (!isAuthenticated && !isLoading) {
    login();
  }

  if (isAuthenticated && !isLoading) return <Outlet />;

  return null;
}
