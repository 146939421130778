import { ReactNode, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Menu, Group } from '@mantine/core';

import { NavigationLink } from '@paytently/types';
import { Icon, TextButton, Title } from '@paytently/ui';

import CheckIcon from '@material-symbols/svg-400/rounded/check.svg?react';

import { NavigationLinks } from '@#/NavigationLinks';

import styles from './MobileNavigation.module.css';

interface RenderLinkProps {
  isActive: boolean;
  link: NavigationLink;
}

const renderLink = ({ isActive, link }: RenderLinkProps): ReactNode => (
  <Group gap="md" align="center" justify="space-between">
    <Title order={3} weight="normal" className={styles.linkLabel}>
      {link.label}
    </Title>

    {isActive && <CheckIcon width={20} fill="var(--mantine-color-primary-3)" />}
  </Group>
);

export function MobileNavigation() {
  const { pathname } = useLocation();

  const { label: activeRoute } = useMemo(() => {
    return NavigationLinks.find((link) => link.path === pathname) ?? { label: 'Fallback' };
  }, [pathname]);

  return (
    <Menu position="bottom-start">
      <Menu.Target>
        <TextButton
          className={styles.menuButton}
          color="var(--mantine-color-white)"
          rightSection={<Icon name="expand_more" />}
        >
          {activeRoute}
        </TextButton>
      </Menu.Target>

      <Menu.Dropdown>
        {NavigationLinks.map((link) => (
          <Menu.Item leftSection={link.icon} key={link.path}>
            <NavLink className={styles.link} to={link.path}>
              {({ isActive }) => renderLink({ isActive, link })}
            </NavLink>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
