import { Section } from './TransactionDetailDataPoints';
import { path, pathOr } from 'ramda';

import { Accordion, Stack } from '@mantine/core';

import { TransactionDetailDataPoint } from '../TransactionDetailDataPoint';

interface Props {
  data: any;
  section: Section;
}

export function TransactionDetailSection({ data, section }: Props) {
  const shouldHide = section.requires && !path(section.requires, data);

  if (shouldHide) return null;

  return (
    <Accordion.Item value={section.id}>
      <Accordion.Control>{section.label}</Accordion.Control>
      <Accordion.Panel>
        <Stack>
          {section.data?.map(
            (datapoint) =>
              datapoint.enabled && (
                <TransactionDetailDataPoint
                  key={datapoint.label}
                  label={datapoint.label}
                  value={pathOr('N/A', datapoint.value, data)}
                  type={datapoint.type}
                  payment={data}
                />
              )
          )}
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
