import { ReactNode, useState } from 'react';

import { Alert, Button, Select, SimpleGrid, Stack, Stepper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ContextModalProps, modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { useGetPaymentById, useRefundPayment } from '@paytently/api';
import {
  CurrencyInput,
  ModalActions,
  ModalControls,
  SnackbarBody,
  Text,
  TextWithLoader,
  Title,
} from '@paytently/ui';
import { TextInput } from '@paytently/ui/src/TextInput';

import ErrorIcon from '@material-symbols/svg-400/rounded/error.svg?react';

import { getConnectorChannelByConnectorName, getFormattedCurrency } from '@#/utils';

export function RefundModal({ context, id, innerProps }: ContextModalProps<{ paymentId: string }>) {
  const onClose = () => context.closeModal(id);

  const { data, isLoading } = useGetPaymentById(innerProps?.paymentId);
  const route = data?.route;
  const connector = route?.channel
    ? getConnectorChannelByConnectorName({
        connectorName: route?.channel.name,
        channelId: route?.channel.id,
      })
    : null;

  const multipleRefundsEnabled = connector?.capabilities.refunds.multiple_partial_enabled;
  const hasBeenRefunded =
    data && data.amount_details.available_to_refund < data.amount_details.total_captured;

  const form = useForm({
    mode: 'controlled',
    validateInputOnChange: true,

    initialValues: {
      amount: '0',
      reason: '',
      reasonDescription: '',
    },

    validate: {
      amount: (value) => {
        if (!data) return true;

        if (value.length === 0) return 'Invalid amount.';

        const valueNum = Number(value);
        if (valueNum <= 0) return 'Refund amount must be greater than zero.';

        if (valueNum > data?.amount)
          return 'Refund amount must be less than the total payment amount.';

        return null;
      },
    },
  });

  const refundPaymentMutation = useRefundPayment();

  const openConfirmModal = () =>
    modals.openContextModal({
      modal: 'confirm',
      centered: true,
      innerProps: {
        children: (
          <>
            <Stack align="center" gap="md">
              <ErrorIcon fill="var(--mantine-color-yellow-0)" />
              <Title order={2} weight="medium">
                Are you sure you want to refund the payment?
              </Title>
            </Stack>
            <Text ta="center">
              If you continue, you will no longer be able to refund on this payment.
            </Text>
          </>
        ),
        onConfirm: initiateRefund,
        onCancel: () => modals.closeAll(),
        labels: { confirm: 'Refund' },
      },
    });

  const initiateRefund = async () => {
    if (!data) return;

    const refundAmount = Number(form.getValues().amount);
    const notificationId = notifications.show({
      message: <SnackbarBody message="Refund processing..." />,
      position: 'bottom-center',
    });

    modals.closeAll();

    try {
      await refundPaymentMutation.mutateAsync({
        id: data?.id as string,
        reference: '',
        amount: refundAmount,
      });

      notifications.update({
        id: notificationId,
        message: (
          <SnackbarBody
            message={`Refund has been requested for the amount of ${getFormattedCurrency({ amount: refundAmount, currencyCode: data.currency })} on payment ${data?.id} `}
          />
        ),
      });
    } catch (error) {
      notifications.clean();

      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: 'Error in refunding payment',
          getSupportAction: () => alert('get support clicked'),
          text: `There was an unexpected error in refunding payment ${data?.id}. We apologise for
        the inconvenience. Please contact your customer support manager.`,
        },
      });
    }
  };

  const [active, setActive] = useState(0);

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const DataText = ({ children }: { children: ReactNode }) => {
    return (
      <TextWithLoader isLoading={isLoading}>
        <Text weight="medium">{children}</Text>
      </TextWithLoader>
    );
  };

  const singleRefundAlert = hasBeenRefunded
    ? `As ${connector?.name} only allows one refund per payment, you may not request another refund on this payment.`
    : `${connector?.name} only allows one refund per payment. If you continue, you will no
                longer be able to refund on this payment.`;

  const content = (
    <Stepper active={active} styles={{ steps: { display: 'none' } }} contentPadding="xs">
      <Stepper.Step>
        <Stack pb="lg">
          <Text>Enter the amount you want to refund.</Text>
          {!multipleRefundsEnabled && (
            <Alert title="Attention" color="yellow.0" w="100%">
              <Text>{singleRefundAlert}</Text>
            </Alert>
          )}
          <SimpleGrid cols={2}>
            <Text>Currency</Text>
            <DataText>{data?.currency}</DataText>
            <Text>Payment amount</Text>
            <DataText>
              {data && getFormattedCurrency({ amount: data.amount, currencyCode: data.currency })}
            </DataText>
            <Text>Available for refund</Text>
            <DataText>
              {data &&
                getFormattedCurrency({
                  amount: data.amount_details.available_to_refund,
                  currencyCode: data.currency,
                })}
            </DataText>
          </SimpleGrid>
          <CurrencyInput
            disabled={isLoading}
            label="Refund amount"
            key={form.key('amount')}
            {...form.getInputProps('amount')}
          />
        </Stack>
      </Stepper.Step>

      <Stepper.Step>
        <Stack gap="lg" pb="lg">
          <Text>Ensure the details below are correct before you confirm the refund.</Text>
          <SimpleGrid cols={2}>
            <Text>Transaction ID</Text>
            <DataText>{data?.id}</DataText>
            <Text>Date</Text>
            <Text>
              <DataText>{data?.requested_on}</DataText>
            </Text>
            <Text>Entity</Text>
            <Text>
              <b>Entity name TBD</b>
            </Text>
            <Text>Currency</Text>
            <DataText>{data?.currency}</DataText>
            <Text>Payment amount</Text>
            <DataText>
              {data && getFormattedCurrency({ amount: data.amount, currencyCode: data.currency })}
            </DataText>
            <Text>Payer Name</Text>
            <DataText>
              {data?.customer.first_name} {data?.customer.last_name}
            </DataText>
            <Text>Card Number</Text>
            <DataText>{data?.method.card.number}</DataText>
          </SimpleGrid>
          <Title order={3}>Refund Details</Title>
          <SimpleGrid cols={2}>
            <Text>Refund Amount</Text>
            <Text>
              {data &&
                getFormattedCurrency({
                  currencyCode: data?.currency,
                  amount: Number(form.getValues().amount),
                })}
            </Text>
          </SimpleGrid>
          <Select
            disabled={isLoading}
            placeholder="Select a refund reason (optional)"
            data={[
              'Payer account/wallet was not credited',
              'Duplicate charges',
              'Product Returned',
              'Customer dissatisfaction',
              'Other',
            ]}
            {...form.getInputProps('reason')}
          />
        </Stack>
        {form.getValues().reason === 'Other' && (
          <TextInput
            {...form.getInputProps('reasonDescription')}
            disabled={isLoading}
            label="Description"
            maxLength={128}
            placeholder="Please enter a refund reason (optional)"
          />
        )}
      </Stepper.Step>
    </Stepper>
  );

  const finalAction = multipleRefundsEnabled ? initiateRefund : openConfirmModal;

  return (
    <>
      <ModalControls title="Refund payment" active={active} prevStep={prevStep} onClose={onClose} />
      {content}
      <ModalActions>
        <Button onClick={onClose} size="md" variant="subtle" color="neutral.3">
          Cancel
        </Button>
        <Button
          disabled={!form.isValid() || isLoading || (!multipleRefundsEnabled && hasBeenRefunded)}
          onClick={active === 0 ? nextStep : finalAction}
          size="md"
        >
          {active === 1 ? 'Refund payment' : 'Continue'}
        </Button>
      </ModalActions>
    </>
  );
}
