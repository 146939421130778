import { Box, Group } from '@mantine/core';
import { DatePickerValue } from '@mantine/dates';

import { DatePickerInput, SearchInput } from '@paytently/ui';

export function SearchBar({
  onSearch,
  onDateChange,
}: {
  onSearch: (params: { option: string; value: string }) => void;
  onDateChange: (newDate: DatePickerValue<'range'>) => void;
}) {
  const searchOptions = [
    { value: 'payment_id', label: 'Payment ID' },
    { value: 'reference', label: 'Reference' },
  ];

  return (
    <Group>
      <Box style={{ flexGrow: 2 }}>
        <SearchInput onSearch={onSearch} searchOptions={searchOptions} />
      </Box>
      <DatePickerInput type="range" onChange={onDateChange} />
    </Group>
  );
}
