import { CountriesByCode, Scheme, Status } from '@paytently/types';

import {
  AppliedFilters,
  FilterOptionType,
  FilterTypes,
  MultiSelectDataType,
} from '@#/components/Filters/FilterTypes';

import {
  generateMinMaxSearchFilter,
  generateObject,
  generateOrUpdateObject,
  getGenericStringArray,
} from '@#/utils';
import { connectorConfiguration } from '@#/utils/getConnectorChannel/connectorConfiguration';

const getChannelFilterData = (): MultiSelectDataType =>
  connectorConfiguration.flatMap((connector) =>
    connector.channels.map(({ id, name }) => ({
      label: name,
      value: id,
    }))
  );

const getCountryCodeFilterData = (): MultiSelectDataType =>
  Object.entries(CountriesByCode).map(([value, label]) => ({ label, value }));

const convertMultiSelectDataToStringArray = (data: MultiSelectDataType): string[] =>
  data.map((dataPoint) => dataPoint.value);

export const paymentFilters: FilterOptionType[] = [
  {
    label: 'Amount',
    description: 'Enter or select minimum and maximum transaction amount',
    type: FilterTypes.range,
    generateSearchFilter: ({ data }: { data: { min?: number; max?: number } }) =>
      generateMinMaxSearchFilter(data, 'amount'),
    props: {
      min: 0,
      max: 100,
    },
  },
  {
    label: 'Status',
    type: FilterTypes.multiSelect,
    generateSearchFilter: (data: MultiSelectDataType) =>
      getGenericStringArray(convertMultiSelectDataToStringArray(data), 'statuses'),
    props: {
      data: Object.values(Status).map((status) => ({ label: status, value: status })),
    },
  },
  {
    label: 'Refunds',
    type: FilterTypes.boolean,
    generateSearchFilter: (data: boolean) => generateObject(data, ['refunds', 'requested']),
    props: {
      data: false,
    },
  },
  {
    label: 'Decline code or reason',
    type: FilterTypes.multiSelect,
    generateSearchFilter: (data: MultiSelectDataType, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(convertMultiSelectDataToStringArray(data), previousFilters, [
        'error_codes',
      ]),
    props: {
      data: [{ label: '0006 - Error	', value: '0006' }],
    },
  },
  {
    label: 'Fail code or reason',
    type: FilterTypes.multiSelect,
    generateSearchFilter: (data: MultiSelectDataType, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(convertMultiSelectDataToStringArray(data), previousFilters, [
        'error_codes',
      ]),
    props: {
      data: [
        { label: '9999 - Error	', value: '9999' },
        { label: '3001 - Void Failed	', value: '3001' },
        { label: '3000 - Refund Failed	', value: '3000' },
      ],
    },
  },
  {
    label: 'Channel',
    type: FilterTypes.multiSelect,
    generateSearchFilter: (data: MultiSelectDataType, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(convertMultiSelectDataToStringArray(data), previousFilters, [
        'routing',
        'channel_ids',
      ]),
    props: {
      data: getChannelFilterData(),
    },
  },
  {
    label: 'Entity',
    internalOnly: true,
    type: FilterTypes.input,
    generateSearchFilter: (data: string, previousFilters: AppliedFilters) =>
      generateOrUpdateObject([data], previousFilters, ['processing', 'entity_ids']),
    props: {
      data: '',
    },
  },
  {
    label: 'Customer ID',
    type: FilterTypes.input,
    generateSearchFilter: (data: string, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(data, previousFilters, ['customer', 'id']),
    props: {
      data: '',
    },
  },
  {
    label: 'Customer name',
    type: FilterTypes.input,
    generateSearchFilter: (data: string, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(data, previousFilters, ['customer', 'name']),
    props: {
      data: '',
    },
  },
  {
    label: 'Billing country',
    type: FilterTypes.multiSelect,
    generateSearchFilter: (data: MultiSelectDataType, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(convertMultiSelectDataToStringArray(data), previousFilters, [
        'billing_address',
        'country_codes',
      ]),
    props: {
      data: getCountryCodeFilterData(),
    },
  },
  {
    label: 'IP Address',
    type: FilterTypes.input,
    generateSearchFilter: (data: string) => getGenericStringArray([data], 'ip_addresses'),
    props: {
      data: '',
    },
  },
  {
    label: 'Card number',
    type: FilterTypes.input,
    generateSearchFilter: (data: string, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(data, previousFilters, ['card', 'number']),
    props: {
      data: '',
    },
  },
  {
    label: 'Scheme',
    type: FilterTypes.schemeSelection,
    generateSearchFilter: (data: MultiSelectDataType, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(convertMultiSelectDataToStringArray(data), previousFilters, [
        'card',
        'scheme_ids',
      ]),
    props: {
      data: Object.values(Scheme).map((scheme) => ({ label: scheme, value: scheme })),
    },
  },
  {
    label: 'Issuing bank',
    type: FilterTypes.input,
    generateSearchFilter: (data: string, previousFilters: AppliedFilters) =>
      generateOrUpdateObject([data], previousFilters, ['card', 'issuing_bank_ids']),
    props: {
      data: '',
    },
  },
  {
    label: 'Issuing country',
    type: FilterTypes.multiSelect,
    generateSearchFilter: (data: MultiSelectDataType, previousFilters: AppliedFilters) =>
      generateOrUpdateObject(convertMultiSelectDataToStringArray(data), previousFilters, [
        'card',
        'issuing_country_codes',
      ]),
    props: {
      data: getCountryCodeFilterData(),
    },
  },
];
