import { addDays, startOfDay } from 'date-fns';
import { useRef, useState } from 'react';

import { Button, Group, Stack, ThemeIcon } from '@mantine/core';
import { DatePickerValue } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';

import { useSearchPayments } from '@paytently/api';
import { PageTitle, Text, Title } from '@paytently/ui';

import { AppliedFiltersDisplay } from '@#/components/Filters/AppliedFiltersDisplay';
import { AppliedFilters } from '@#/components/Filters/FilterTypes';
import { FiltersSideBar } from '@#/components/Filters/FiltersSideBar';

import CreditCard from '@material-symbols/svg-400/rounded/credit_card.svg?react';
import FilterAlt from '@material-symbols/svg-400/rounded/filter_alt.svg?react';

import { ExportReport } from '@#/components';
import { useInfiniteScroll } from '@#/hooks/useInfiniteScroll';
import { paymentFilters } from '@#/pages/Payments/PaymentFilters';
import { PaymentsTable } from '@#/pages/Payments/PaymentsTable';
import { SearchBar } from '@#/pages/Payments/SearchBar';

export function Payments() {
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const [filtersOpened, { toggle: toggleFilters, close: closeFilters }] = useDisclosure();
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilters>({});
  const [searchBarFilter, setSearchBarFilter] = useState({});

  const [dateRange, setDateRange] = useState<DatePickerValue<'range'>>([new Date(), new Date()]);

  // TODO: Extract to common util function
  function extractSearchFilter() {
    let searchFilters = {};

    for (const [key, value] of Object.entries(appliedFilters)) {
      // Check if the property 'searchFilter' exists within the value
      if (value && value.hasOwnProperty('searchFilter')) {
        searchFilters = { ...searchFilters, ...value.searchFilter };
      }
    }

    return searchFilters;
  }

  const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useSearchPayments({
    pagination: { page: 0, size: 50 },
    filters: {
      // from: midnight of selected day
      // to: midnight of day after selected day
      requested_on: {
        from: startOfDay(dateRange?.[0]!).toISOString(),
        to: startOfDay(addDays(dateRange?.[1]!, 1)).toISOString(),
      },
      ...searchBarFilter,
      ...extractSearchFilter(),
    },
  });

  useInfiniteScroll({
    observerTarget: tableBodyRef,
    pages: data?.pages || [],
    pagination: data?.pagination,
    isFetchingNextPage,
    fetchNextPage,
  });

  return (
    <>
      <FiltersSideBar
        opened={filtersOpened}
        close={closeFilters}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        filterOptions={paymentFilters}
      />
      <Stack gap="lg" px="xl" pt="lg">
        <Group justify="space-between">
          <PageTitle title="Payments" IconComponent={CreditCard} />
          <ExportReport data={data} />
        </Group>

        <SearchBar
          onDateChange={setDateRange}
          onSearch={({ option, value }) => setSearchBarFilter({ [option]: value })}
        />
        <Stack gap="xs">
          <Group justify="space-between">
            <Text weight="medium">{data?.pagination.total_hits} items</Text>
            <Button
              onClick={toggleFilters}
              size="md"
              variant="subtle"
              color="neutral.3"
              leftSection={<FilterAlt />}
            >
              Filters
            </Button>
          </Group>
          <AppliedFiltersDisplay
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
          <PaymentsTable
            data={data?.items ?? []}
            tableBodyRef={tableBodyRef}
            isLoading={isFetching}
          />
        </Stack>
      </Stack>
    </>
  );
}
