import { useMutation } from "@tanstack/react-query";

import { AddNewUserRequest } from "@paytently/types";
import { addNewUser } from "../../api/authentication";

export function useAddNewUser() {
  const mutation = useMutation({
    mutationFn: ({
      entityId,
      user,
    }: {
      entityId: string;
      user: AddNewUserRequest;
    }) => addNewUser({ entityId, user }),
  });

  return mutation;
}
