import { useSearchParams } from 'react-router-dom';

import { Badge, Flex, Group, Stack } from '@mantine/core';

import { PaymentResponse, Status, StatusType } from '@paytently/types';
import { Text, Title } from '@paytently/ui';

import { DisplayWithPaymentStatus } from '@#/components/DisplayWithPaymentStatus';

import { ChargebackAlert } from '../ChargebackAlert';
import { PaymentMetrics } from '../PaymentMetrics';
import { RejectedAlert } from '../RejectedAlert/RejectedAlert';
import { TransactionDetail } from '../TransactionDetail';
import { TransactionDetailTabs } from '../TransactionDetailTabs';
import { useFormattedDate } from '@#/hooks';

import styles from './TransactionDetailSummary.module.css';

interface Props {
  readonly data: PaymentResponse;
}

export function TransactionDetailSummary({ data }: Props) {
  const [searchParams, _] = useSearchParams();

  // TODO: Remove when we have a chargeback implementation for payments
  const shouldShowChargebackStatus = Boolean(searchParams.get('cbdemo') === 'true');

  const date = useFormattedDate({ date: data.requested_on });

  return (
    <Stack w="100%" gap="lg" className={styles.paymentDetailsSummaryContainer}>
      <Text color="var(--mantine-color-neutral-2)" weight="medium">
        {date}
      </Text>

      <Flex className={styles.transactionIdContainer} gap="sm" justify="space-between">
        <Group gap="sm">
          <Title order={2}>{data.id}</Title>
          {shouldShowChargebackStatus && (
            <Badge variant="filled" color="yellow.0">
              Chargeback
            </Badge>
          )}
        </Group>

        <Flex gap="xs" align="baseline">
          <Title>{data.amount}</Title>

          <Text weight="medium" color="var(--mantine-color-neutral-2)">
            {data.currency}
          </Text>
        </Flex>
      </Flex>

      {shouldShowChargebackStatus && (
        <ChargebackAlert
          description="Do not refund this transaction 48 hours after the date and time of the chargeback alert"
          date="17/05/2024 17:52"
          reason="10.1 EMV Liability Shift Counterfeit Fraud"
        />
      )}

      <PaymentMetrics data={data} date={date} />

      <DisplayWithPaymentStatus
        paymentStatus={data.status}
        withStatuses={[Status.declined, Status.failed]}
      >
        {data.errors && (
          <RejectedAlert
            code={data.errors[0].code || 'N/A'}
            message={data.errors[0].reason || 'N/A'}
            status={data.status}
          />
        )}
      </DisplayWithPaymentStatus>

      <TransactionDetailTabs details={<TransactionDetail data={data} />} />
    </Stack>
  );
}
