import Axios from "axios";

export const axios = Axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_ENDPOINT,
  headers: {
    "x-internal-auth": import.meta.env.VITE_GATEWAY_AUTH_KEY,
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});
