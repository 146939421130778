import { UserManagementTable } from './UserManagementTable';

import { Group, Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { useGetUserList, useSuspendUser } from '@paytently/api';
import { PageProps, SuspendUserRequest, UserResponse } from '@paytently/types';
import { PageTitle, TextButton, SnackbarBody, useDisplayMode } from '@paytently/ui';

import { openMobileOrDesktopModal } from '@#/components/Modals/modalHelpers';

import ManageAccounts from '@material-symbols/svg-400/rounded/manage_accounts.svg?react';
import AddUserIcon from '@material-symbols/svg-400/rounded/person_add.svg?react';

import { useDocumentTitle } from '@#/hooks';

export function UserManagement({ title }: PageProps) {
  useDocumentTitle(title);

  const { data } = useGetUserList('TODO');
  const suspendUserMutation = useSuspendUser();

  const suspendUser = async ({
    entityId,
    userId,
    userDetails,
  }: SuspendUserRequest & { userDetails: UserResponse }) => {
    try {
      await suspendUserMutation.mutateAsync({ entityId, userId });
      notifications.show({
        message: (
          <SnackbarBody
            message={`${userDetails.first_name} ${userDetails.last_name} has been deleted successfully`}
          />
        ),
        position: 'bottom-center',
      });
    } catch (error) {
      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: 'Error in deleting user',
          getSupportAction: () => alert('get support clicked'),
          text: 'There was an unexpected error in removing this user. We apologise for the inconvenience. If the error appears again please contact your customer support manager.',
        },
      });
    }
  };

  const { isInMobileMode } = useDisplayMode();

  const openAddUserModal = () => {
    openMobileOrDesktopModal(isInMobileMode, {
      modal: 'addOrEditUser',
      size: '512px',
      innerProps: { title: 'Add new user' },
    });
  };

  return (
    <Stack gap="lg" px="xl" pt="lg">
      <Group justify="space-between">
        <PageTitle title="User Management" IconComponent={ManageAccounts} />
        <TextButton ml="auto" icon={<AddUserIcon />} onClick={openAddUserModal}>
          Add user
        </TextButton>
      </Group>

      <UserManagementTable data={data || []} isLoading={false} onSuspendUser={suspendUser} />
    </Stack>
  );
}
