import { connectorConfiguration } from './connectorConfiguration';

import { ConnectorChannel } from '@paytently/types';

export const getConnectorChannelByChannelId = ({
  channelId,
}: {
  channelId: string;
}): ConnectorChannel | null => {
  for (const connector of connectorConfiguration) {
    const channel = connector.channels.find(({ id }) => id === channelId);

    if (channel) return channel;
  }

  return null;
};

export const getConnectorChannelByConnectorName = ({
  connectorName,
  channelId,
}: {
  connectorName: string;
  channelId: string;
}) => {
  const connector = connectorConfiguration.find(({ name }) => name === connectorName);
  if (!connector) return null;
  return connector.channels.find(({ id }) => id === channelId);
};
