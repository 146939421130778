import { MRT_ColumnDef, MRT_Row } from 'mantine-react-table';
import { useMemo } from 'react';

import { Menu } from '@mantine/core';

import { SuspendUserRequest, UserListResponse, UserResponse } from '@paytently/types';

import { DataTable } from '@#/components/DataTable';

import Delete from '@material-symbols/svg-400/rounded/delete.svg?react';
import Edit from '@material-symbols/svg-400/rounded/edit.svg?react';
import LockReset from '@material-symbols/svg-400/rounded/lock_reset.svg?react';

export const UserManagementTable = ({
  data,
  tableBodyRef,
  isLoading,
  onSuspendUser,
}: {
  data: UserListResponse;
  tableBodyRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
  isLoading: boolean;
  onSuspendUser: ({
    userId,
    entityId,
    userDetails,
  }: SuspendUserRequest & { userDetails: UserResponse }) => void;
}) => {
  const dataWithoutSuspendedUsers = data.filter((user) => user.is_suspended === false);

  const columns = useMemo<MRT_ColumnDef<UserResponse>[]>(
    () => [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        id: 'User',
        header: 'User',
        enableSorting: false,
        grow: false,
        size: 256,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: false,
        grow: false,
        size: 256,
      },
      {
        accessorKey: 'created_on',
        header: 'Date Added',
      },
      {
        accessorKey: 'last_signed_in',
        header: 'Last Login',
      },
    ],
    []
  );

  const onRowClick = (row: MRT_Row<UserResponse>) => {
    console.log('Clicked row: ', row.id);
  };

  const renderRowMenu = ({ row }: { row: MRT_Row<UserResponse> }) => {
    return (
      <>
        <Menu.Item
          leftSection={<Edit width={20} />}
          onClick={() => console.log('Clicked edit user details on row: ', row.id)}
        >
          Edit user details
        </Menu.Item>
        <Menu.Item
          leftSection={<LockReset width={20} />}
          onClick={() => console.log('Clicked reset password on row: ', row.id)}
        >
          Reset password
        </Menu.Item>
        <Menu.Item
          leftSection={<Delete width={20} />}
          onClick={() =>
            onSuspendUser({ userId: row.original.id, entityId: 'TODO', userDetails: row.original })
          }
        >
          Delete
        </Menu.Item>
      </>
    );
  };

  return (
    <DataTable<UserResponse>
      data={dataWithoutSuspendedUsers}
      columns={columns}
      isLoading={isLoading}
      onRowClick={onRowClick}
      tableBodyRef={tableBodyRef}
      renderRowMenu={renderRowMenu}
    />
  );
};
