import { modals } from '@mantine/modals';

import { Status, StatusType } from '@paytently/types';
import { TextButton, useDisplayMode } from '@paytently/ui';

import { openMobileOrDesktopModal } from '@#/components/Modals/modalHelpers';

import CreditCardOffIcon from '@material-symbols/svg-400/rounded/credit_card_off.svg?react';
import UndoIcon from '@material-symbols/svg-400/rounded/undo.svg?react';

interface Props {
  paymentStatus: StatusType;
  paymentId: string;
}

export function PaymentActionButton({ paymentStatus, paymentId }: Props) {
  const { isInMobileMode } = useDisplayMode();

  const openRefundModal = () => {
    openMobileOrDesktopModal(isInMobileMode, {
      modal: 'refund',
      size: '512px',
      innerProps: { paymentId },
    });
  };

  const openVoidModal = () => {
    openMobileOrDesktopModal(isInMobileMode, {
      modal: 'void',
      size: '512px',
      innerProps: { paymentId },
    });
  };

  switch (paymentStatus) {
    case Status.captured:
      return (
        <TextButton ml="auto" icon={<UndoIcon />} onClick={openRefundModal}>
          Refund
        </TextButton>
      );

    case Status.pending:
      return (
        <TextButton ml="auto" icon={<CreditCardOffIcon />} onClick={openVoidModal}>
          Void
        </TextButton>
      );
    default:
      return null;
  }
}
