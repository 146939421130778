import { PrivateRoute, TransactionQuickView } from './components';
import { datadogRum } from '@datadog/browser-rum';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { PaymentDetails, Dashboard, UserManagement } from '@#/pages';
import { MainLayout, PaymentsPageLayout } from '@#/pages/Layouts';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            path: '/',
            element: <Dashboard title="Home" />,
          },
          {
            path: 'payments',
            element: <PaymentsPageLayout title="Payments" />,
            children: [
              {
                path: ':id',
                element: <TransactionQuickView />,
              },
            ],
          },
          {
            path: 'user_management',
            element: <UserManagement title="User management" />,
          },
          {
            path: 'details/:id',
            element: <PaymentDetails title="Payment details" />,
          },
        ],
      },
    ],
  },
]);

export function Router() {
  const { user } = useKindeAuth();

  if (user?.id) datadogRum.setUser({ id: user.id });

  return <RouterProvider router={router} />;
}
