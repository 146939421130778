import { useEffect, useState } from 'react';

import { Button, Stack } from '@mantine/core';

import { SideSheet } from '@paytently/ui/src/SideSheet/SideSheet';

import { AppliedFilters, FilterOptionType } from '@#/components/Filters/FilterTypes';
import { FilterOption } from '@#/components/Filters/FiltersSideBar/FilterOption';

import styles from './FiltersSideBar.module.css';

type FiltersProps = {
  opened: boolean;
  appliedFilters: AppliedFilters;
  setAppliedFilters: (filters: AppliedFilters) => void;
  filterOptions: FilterOptionType[];
  close: () => void;
};

export function FiltersSideBar({
  opened,
  close,
  filterOptions,
  appliedFilters,
  setAppliedFilters,
}: FiltersProps) {
  const [tempFilters, setTempFilters] = useState<AppliedFilters>(appliedFilters);

  useEffect(() => {
    setTempFilters(appliedFilters);
  }, [appliedFilters]);

  function applyFilters() {
    setAppliedFilters(tempFilters);
    close();
  }

  function clearFilters() {
    setAppliedFilters({});
  }

  return (
    <SideSheet
      title="Filters"
      opened={opened}
      onClose={close}
      onBack={close}
      primaryButton={<Button onClick={applyFilters}>View all</Button>}
      secondaryButton={
        <Button onClick={clearFilters} variant="subtle">
          Clear all
        </Button>
      }
    >
      <Stack gap={24} className={styles.filtersSideBarBody}>
        {filterOptions.map((filterOption) => (
          <FilterOption
            key={filterOption.label}
            {...filterOption}
            tempFilters={tempFilters}
            setTempFilters={setTempFilters}
          />
        ))}
      </Stack>
    </SideSheet>
  );
}
