import { forwardRef, ReactNode, useRef, useState } from 'react';

import {
  Group,
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

import { LabelText, Text } from '../Typography';

import styles from './TextInput.module.css';

export function TextInputExtend() {
  return MantineTextInput.extend({
    defaultProps: {
      radius: '12px',
      leftSectionWidth: '44px',
    },

    classNames: {
      required: styles.required,
      label: styles.label,
      description: styles.description,
      error: styles.error,
    },
  });
}

export type TextInputProps = MantineTextInputProps & {
  supportingText?: string;
  maxCharacters?: number;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { label, supportingText, error, description, maxLength, value, ...args }: TextInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [scrollLeft, setScrollLeft] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    const mergedRef = useMergedRef(inputRef, ref);

    const handleScroll = () => {
      if (inputRef.current) {
        setScrollLeft(inputRef.current.scrollLeft);
      }
    };

    const handleBlur = () => {
      if (inputRef.current) {
        inputRef.current.scrollLeft = scrollLeft;
      }
    };

    const maxCharactersIndicator = () => {
      return (
        <LabelText ml="auto">
          {value?.toString().length} / {maxLength}
        </LabelText>
      );
    };

    /**
     * If the TextInput has a character limit, the indicator needs to be inserted alongside either the label or description.
     * This function figures out where it should go and binds them together.
     */
    const wrapLabelsWithMaxCharacterIndicator = () => {
      if (!maxLength) {
        return {
          label,
          description,
        };
      }

      if (!description) {
        return {
          label: (
            <Group justify="space-between">
              {label}
              {maxCharactersIndicator()}
            </Group>
          ),
          description,
        };
      }

      return {
        label,
        description: (
          <Group justify="space-between">
            {description}
            {maxCharactersIndicator()}
          </Group>
        ),
      };
    };

    const { label: wrappedLabel, description: wrappedDescription } =
      wrapLabelsWithMaxCharacterIndicator();

    return (
      <>
        <MantineTextInput
          label={wrappedLabel}
          description={wrappedDescription}
          onScroll={handleScroll}
          onBlur={handleBlur}
          ref={mergedRef}
          error={error}
          value={value}
          maxLength={maxLength}
          {...args}
        />
        {supportingText && !error && (
          <Text className={styles.supportingText}>{supportingText}</Text>
        )}
      </>
    );
  }
);
