import { useNavigate, useParams } from 'react-router-dom';

import { AppShell } from '@mantine/core';

import { useDisplayMode } from '@paytently/ui';

import TransactionDetails from '../TransactionDetails/TransactionDetails';

export function TransactionQuickView() {
  const { id: transactionId } = useParams();
  const navigate = useNavigate();
  const { isInMobileMode } = useDisplayMode();

  if (!transactionId) return null;

  if (isInMobileMode) navigate(`/details/${transactionId}`, { replace: true });

  return (
    <AppShell.Aside>
      <TransactionDetails />
    </AppShell.Aside>
  );
}
