import { TransactionDetailsHeader, TransactionDetailSummary } from './components';
import { useParams } from 'react-router-dom';

import { Box, Flex, ScrollArea } from '@mantine/core';

import { useGetPaymentById } from '@paytently/api';

import { TransactionQuickViewHeader } from '../TransactionQuickView/components';
import { useIsPaymentsQuickViewRoute } from '@#/hooks';

import styles from './TransactionDetails.module.css';

function TransactionDetails() {
  const isPaymentsQuickView = useIsPaymentsQuickViewRoute();
  const { id: transactionId } = useParams();

  if (!transactionId) return null;

  const { data } = useGetPaymentById(transactionId);

  if (!transactionId) return null;

  return (
    <Box className={styles.paymentDetailsContainer}>
      {isPaymentsQuickView && <TransactionQuickViewHeader transactionId={transactionId} />}
      {data && <TransactionDetailsHeader paymentData={data} />}
      <ScrollArea
        classNames={{ root: styles.paymentDetailsScrollArea }}
        data-payments-view={isPaymentsQuickView ? 'quick-view' : 'full-view'}
      >
        <Flex px="xl" py="lg">
          {data && <TransactionDetailSummary data={data} />}
        </Flex>
      </ScrollArea>
    </Box>
  );
}

export default TransactionDetails;
