import {
  AmountCell,
  ChannelCell,
  DateCell,
  GenericCell,
  SchemeCell,
  StatusCell,
} from './PaymentsTableCells';
import { MRT_ColumnDef, MRT_Row } from 'mantine-react-table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Menu } from '@mantine/core';

import { PaymentsSearchResponseItem, Status } from '@paytently/types';
import { useDisplayMode } from '@paytently/ui';

import { DataTable } from '@#/components/DataTable';
import { openMobileOrDesktopModal } from '@#/components/Modals/modalHelpers';

import CreditCardOff from '@material-symbols/svg-400/rounded/credit_card_off.svg?react';
import QuickReferenceAll from '@material-symbols/svg-400/rounded/quick_reference_all.svg?react';

import { DisplayWithPaymentStatus } from '@#/components';

export type PaymentsDataTableItem = Pick<
  PaymentsSearchResponseItem,
  'id' | 'status' | 'amount' | 'customer' | 'method' | 'requested_on' | 'route'
>;

export const PaymentsTable = ({
  data,
  tableBodyRef,
  isLoading,
}: {
  data: PaymentsSearchResponseItem[];
  tableBodyRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
  isLoading: boolean;
}) => {
  const { isInMobileMode } = useDisplayMode();
  const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<PaymentsDataTableItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Transaction ID',
        enableSorting: false,
        grow: false,
        size: 256,
        Cell: GenericCell,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        Cell: StatusCell,
      },
      {
        accessorKey: 'requested_on',
        header: 'Date',
        Cell: DateCell,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        Cell: AmountCell,
      },
      {
        accessorKey: 'route',
        header: 'Channel',
        enableSorting: false,
        Cell: ChannelCell,
      },
      {
        accessorFn: (row) =>
          row.customer ? `${row.customer?.first_name} ${row.customer?.last_name}` : undefined,
        header: 'Customer name',
        enableSorting: false,
        Cell: GenericCell,
      },
      // TODO - Reintroduce when supported by API: https://paytentlydev.atlassian.net/browse/COPS-799
      // {
      //   accessorFn: (row) => `${row.method?.scheme}`,
      //   header: 'Scheme',
      //   enableSorting: false,
      //   grow: false,
      //   Cell: SchemeCell,
      // },
    ],
    [isInMobileMode]
  );

  const onTransactionClick = (row: MRT_Row<PaymentsDataTableItem>, fullView?: boolean) => {
    if (isInMobileMode || fullView) {
      return navigate(`/details/${row.original.id}`);
    }

    return navigate(row.original.id);
  };

  const renderRowMenu = ({ row }: { row: MRT_Row<PaymentsDataTableItem> }) => {
    return (
      <>
        <Menu.Item
          onClick={() => onTransactionClick(row, true)}
          leftSection={<QuickReferenceAll width={20} />}
        >
          View details
        </Menu.Item>
        <DisplayWithPaymentStatus
          paymentStatus={row.original.status}
          withStatuses={[Status.pending]}
        >
          <Menu.Item
            leftSection={<CreditCardOff width={20} />}
            onClick={() =>
              openMobileOrDesktopModal(isInMobileMode, {
                modal: 'void',
                size: '512px',
                innerProps: {
                  paymentId: row.original.id,
                },
              })
            }
          >
            Void
          </Menu.Item>
        </DisplayWithPaymentStatus>
        <DisplayWithPaymentStatus
          paymentStatus={row.original.status}
          withStatuses={[Status.captured]}
        >
          <Menu.Item
            leftSection={<CreditCardOff width={20} />}
            onClick={() =>
              openMobileOrDesktopModal(isInMobileMode, {
                modal: 'refund',
                size: '512px',
                innerProps: {
                  paymentId: row.original.id,
                },
              })
            }
          >
            Refund
          </Menu.Item>
        </DisplayWithPaymentStatus>
      </>
    );
  };

  return (
    <DataTable<PaymentsDataTableItem>
      data={data}
      columns={columns}
      isLoading={isLoading}
      onRowClick={onTransactionClick}
      tableBodyRef={tableBodyRef}
      renderRowMenu={renderRowMenu}
      initialState={{ sorting: [{ id: 'requested_on', desc: true }] }}
    />
  );
};
