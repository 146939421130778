import { useLocalStorage } from '@mantine/hooks';
import { modals } from '@mantine/modals';

import { TextButton } from '@paytently/ui';

import DownloadIcon from '@material-symbols/svg-400/rounded/file_save.svg?react';

import { useDownloadJsonAsCsv } from '@#/hooks';

interface Props {
  data: any; // TODO: use the new PaymentsSearchResponse type once that is merged in
}

export function ExportReport({ data }: Props) {
  const [hasAcceptedExportReportTerms, setHasAcceptedExportReportTerms] = useLocalStorage({
    key: 'export-payments-report-terms-accepted',
    defaultValue: 'false',
  });

  const { downloadCsv } = useDownloadJsonAsCsv(data, 'my-data.csv');

  function handleExportReportClick() {
    if (hasAcceptedExportReportTerms === 'false') {
      return modals.openContextModal({
        modal: 'exportReportTerms',
        innerProps: {
          confirmAction: () => downloadCsv(),
          setTermsCheckedPreference: setHasAcceptedExportReportTerms,
        },
      });
    }

    return downloadCsv();
  }

  return (
    <TextButton ml="auto" icon={<DownloadIcon />} onClick={handleExportReportClick}>
      Export report
    </TextButton>
  );
}
