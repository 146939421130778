import { IconInfoCircle } from '@tabler/icons-react';

import { Alert, Group, Stack } from '@mantine/core';

import { Text } from '@paytently/ui';

import styles from './ChargebackAlert.module.css';

interface Props {
  description: string;
  date: string;
  reason: string;
}

export function ChargebackAlert({ description, date, reason }: Props) {
  const icon = <IconInfoCircle />;
  return (
    <Alert w="100%" variant="light" color="yellow.0" title="Chargeback alert">
      <Stack>
        <Text>
          <span className={styles.chargebackDescriptionLead}>Warning: </span>
          {description}
        </Text>
        <Group wrap="nowrap" align="top">
          <Text>Date of Alert:</Text>
          <Text weight="medium">{date}</Text>
        </Group>
        <Group wrap="nowrap" align="top">
          <Text>Reason:</Text>
          <Text weight="medium">{reason}</Text>
        </Group>
      </Stack>
    </Alert>
  );
}
