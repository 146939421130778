import { AddNewUserRequest } from "@paytently/types";
import axios from "axios";

export async function getUserList(entityId: string) {
  const response = await axios.get(`/auth/entities/${entityId}/users`);
  return response.data;
}

export async function suspendUser({
  entityId,
  userId,
}: {
  entityId: string;
  userId: string;
}) {
  const response = await axios.patch(
    `/auth/entities/${entityId}/users/${userId}`,
    { is_suspended: true },
  );

  return response.data;
}

export async function addNewUser({
  entityId,
  user,
}: {
  entityId: string;
  user: AddNewUserRequest;
}) {
  const response = await axios.post(`/auth/entities/${entityId}/users`, user);

  return response.data;
}
